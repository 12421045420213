import { FieldSet, RecordData } from "airtable"
import Link from "next/link"
import ReactMarkdown from "react-markdown"

const ArtistMessage = (props: RecordData<FieldSet>) => {
  const primary_link = props.fields.primary_link && props.fields.primary_link.toString()
  const summary = props.fields.summary ? props.fields.summary : ""

  return (
    <div tw="px-4">
      <div tw="text-lg tablet:()">
        <ReactMarkdown>{summary.toString()}</ReactMarkdown>
      </div>

      {primary_link && (
        <p tw="pt-4 space-x-2 text-xs font-sans uppercase">
          <Link tw="" href={`/artist`}>
            <span>The Artist</span>
          </Link>
          <span>|</span>
          <Link href={`/foundation`}>
            <span>The Foundation</span>
          </Link>
        </p>
      )}
    </div>
  )
}

export default ArtistMessage
