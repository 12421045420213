import tw from "twin.macro"
import Image from "next/image"
import { Attachment } from "airtable"

interface HeroProps {
  image_url: string
  image_preview: Array<Attachment>
}

const Hero = (props: HeroProps) => {
  const { image_url, image_preview } = props

  const thisImage = image_preview[0].thumbnails && image_preview[0].thumbnails.full
  const width = thisImage && thisImage.width
  const height = thisImage && thisImage.height

  const altText = `The Richard Pousette-Dart Foundation`

  return (
    <>
      <section tw="bg-rpd-gray-8">
        <div tw="max-w-screen-widescreen widescreen:(mx-auto px-4) relative flex flex-col tablet:(flex-row items-center)">
          <div tw="hidden w-full relative h-72 tablet:(block w-1/2 h-full)">
            <div tw="w-full h-full flex justify-center items-center tablet:(px-4)">
              <h1
                tw="text-2xl pb-10 text-white font-sans font-light uppercase desktop:(text-3xl) desktop-lg:(text-4xl)"
                css="letter-spacing:0.06em;"
              >
                <span tw="block">The Richard</span> <span tw="block">Pousette-Dart </span>
                <span tw="block">Foundation</span>
              </h1>
            </div>
          </div>
          <div tw="w-full relative tablet:(w-1/2)" css="">
            <div tw="flex items-center justify-center overflow-hidden">
              <Image
                src={image_url}
                alt={altText}
                priority={true}
                width={width}
                height={height}
                sizes="(max-width: 768px) 100vw, 50vw"
                css={[tw`!h-auto !relative`]}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Hero
