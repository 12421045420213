import tw from "twin.macro"
import Image from "next/image"
import { Attachment, FieldSet, RecordData } from "airtable"
import ReactMarkdown from "react-markdown"
import Link from "next/link"

export const LongArrow = (
  <svg width="32" height="8" viewBox="0 0 32 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.3536 4.35355C31.5488 4.15829 31.5488 3.84171 31.3536 3.64645L28.1716 0.464466C27.9763 0.269204 27.6597 0.269204 27.4645 0.464466C27.2692 0.659728 27.2692 0.976311 27.4645 1.17157L30.2929 4L27.4645 6.82843C27.2692 7.02369 27.2692 7.34027 27.4645 7.53553C27.6597 7.7308 27.9763 7.7308 28.1716 7.53553L31.3536 4.35355ZM0 4.5H31V3.5H0V4.5Z"
      fill="black"
    />
  </svg>
)
export const ShortArrow = (
  <svg width="21" height="8" viewBox="0 0 21 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64645L17.1716 0.464466C16.9763 0.269204 16.6597 0.269204 16.4645 0.464466C16.2692 0.659728 16.2692 0.976311 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.7308 16.9763 7.7308 17.1716 7.53553L20.3536 4.35355ZM0 4.5H20V3.5H0V4.5Z"
      fill="black"
    />
  </svg>
)

interface CardImageProps {
  body: string
  year: string
  image_url: string
  image_preview: Array<Attachment>
  caption: string
}

const CardImage = (props: CardImageProps) => {
  const { caption, image_preview, image_url } = props

  if (!image_url || !image_preview) {
    return <></>
  }

  const thisImage = image_preview[0].thumbnails && image_preview[0].thumbnails.large
  const width = thisImage && thisImage.width
  const height = thisImage && thisImage.height

  const altText = caption
    ? `${caption.toString()} – The Richard Pousette-Dart Foundation`
    : `The Richard Pousette-Dart Foundation`

  return (
    <div tw="w-full relative">
      <Image src={image_url} alt={altText} width={width} height={height} sizes="(max-width: 768px) 100vw, 50vw" />
    </div>
  )
}

interface CardProps {
  title: string
  summary: string
  body: string
  year: string
  image_url: string
  image_preview: Array<Attachment>
  caption: string
  type: string
  kicker: string
  primary_link: string
}
const Card = (props: CardProps) => {
  const type = props.type && props.type.toString()
  const kicker = props.kicker
  const primary_link = props.primary_link
  const title = props.title ? props.title : ""
  const summary = props.summary ? props.summary : ""

  if (!primary_link) {
    return <></>
  }

  interface MoreProps {
    type: string
  }
  const MoreText = ({ type }: MoreProps) => {
    if (type == "Feature") {
      return (
        <Link tw="space-x-2 text-right text-xs font-sans flex justify-end h-full" href={`/features`}>
          <span tw="uppercase">All Features</span> <span tw="flex flex-col justify-center">{LongArrow}</span>
        </Link>
      )
    }
    if (type == "Work") {
      return (
        <Link tw="space-x-2 text-right text-xs font-sans flex justify-end h-full" href={`/work`}>
          <span tw="uppercase">All Works</span> <span tw="flex flex-col justify-center">{LongArrow}</span>
        </Link>
      )
    }
    return (
      <Link tw="space-x-2 text-right text-xs font-sans flex justify-end h-full" href={primary_link.toString()}>
        <span tw="uppercase">View</span> <span tw="flex flex-col justify-center">{LongArrow}</span>
      </Link>
    )
  }

  return (
    <div>
      <Link tw="block hover:(no-underline)" href={primary_link.toString()}>
        <div tw="shadow-lg min-h-card-lg p-4 pb-8 space-y-4 tablet:(space-y-4) bg-rpd-gray-2 bg-opacity-20">
          <CardImage {...props} />

          <h4 tw="text-2xl tablet-lg:(text-2xl) desktop:(text-2xl-half)" css="letter-spacing: -0.04em;">
            {kicker && <em>{kicker.toString()}: </em>}
            <ReactMarkdown components={{ p: "span" }}>{title.toString()}</ReactMarkdown>
          </h4>
          <div tw="text-sm">
            <ReactMarkdown>{summary.toString()}</ReactMarkdown>
          </div>
        </div>
      </Link>
      {type && (
        <div tw="pt-4">
          <MoreText type={type} />
        </div>
      )}
    </div>
  )
}

export default Card
