import type { NextPage } from "next"
import { NextSeo } from "next-seo"
import { IPageFields } from "../@types/contentful"
import Home from "../components/home"
import { base, minifyRecords } from "./api/utils/airtable"

// The props for our page component
interface Props {
  pages: IPageFields[]
}

const HomePage: NextPage<Props> = (props: any) => {
  return (
    <>
      <NextSeo />
      <Home {...props} />
    </>
  )
}

export default HomePage

export async function getStaticProps() {
  const table = base("Homepage")
  const allRecords = await table.select({ view: "Published" }).all()

  return {
    props: {
      allRecords: minifyRecords(allRecords),
    },
  }
}
