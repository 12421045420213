import { Attachment, FieldSet, RecordData, Records } from "airtable"
import Hero from "./hero"
import Card from "./card"
import ArtistMessage from "./artistMessage"

interface HomeProps {
  allRecords: Records<FieldSet>
}
const Home = ({ allRecords }: HomeProps) => {
  const hero = allRecords.filter((el) => el.fields.type === "Hero image")
  const heroFields = hero[0].fields
  const hero_image_url = heroFields.image_url
  const hero_image_preview: any = heroFields.image_preview
  const home = allRecords.filter((el) => el.fields.type === "Home")

  // exclude "Hero images" and "Home" from the records
  const cards = allRecords.filter((el) => el.fields.type != "Hero image" && el.fields.type != "Home")

  return (
    <>
      {hero_image_url && hero_image_preview && (
        <Hero image_url={hero_image_url.toString()} image_preview={hero_image_preview} />
      )}

      <section tw="py-10">
        <div tw="max-w-screen-desktop-lg desktop-lg:mx-auto px-4 relative">
          <div tw="grid grid-cols-4 tablet:(grid-cols-12) gap-6 desktop:(gap-8) desktop-lg:(gap-10)">
            {home.map((item: RecordData<FieldSet>, i: number) => (
              <div tw="col-span-4 tablet:(col-span-12) desktop:(col-span-10 col-start-2)" key={i}>
                <ArtistMessage {...item} />
              </div>
            ))}

            {cards.map((item: any, i: number) => (
              <div tw="col-span-4 tablet:(col-span-6)" key={i}>
                <Card {...item.fields} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
